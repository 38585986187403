import $ from 'jquery';
import 'owl.carousel'; 
import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'

$(window).on('load', function() {

	/* scroll to links */

    // on page load
	if( $('#_' + window.location.hash.replace('#', '')).length > 0 ){

		var elem = $('#_' + window.location.hash.replace('#', ''));
	    if(elem) {

	    	setTimeout(function(){

		    	$('html, body').animate({
		            scrollTop: (elem.offset().top - 100)
		        }, 1000, 'swing');

		    }, 500);
	         
	    }

	}

    // on click 
    $('.scroll-to-link').each(function(){
        
        let link = $(this);
        let href = link.attr('href');
        let href_breakdown = href.split('#');

        link.click(function(){
            if( $(window).width() < 1025 ){
                fadeOutAndHide( $('#site-navigation') );
            }
            scroll_to_elem( $('#_' + href_breakdown[1]) );
            return false;
        });

    });


	/* tablet main navigation */

	$('#primary-menu-btn').click(function(){
		fadeInAndShow( $('#site-navigation') );
	});

	$('#primary-menu-close-btn').click(function(){
		fadeOutAndHide( $('#site-navigation') );
	});	

	$('.menu-item-has-children > a').click(function(){

		$(this).toggleClass('active');

		if( $(this).hasClass('active') ){

			$(this).closest('li').find('ul').first().slideDown(400);

		} else {

			$(this).closest('li').find('ul').first().slideUp(400);

		}

		return false;

	});



    /* add is-scroll class at is-scrolled-point */
    if( $('.site-header').length > 0 ){
        if( $('.site-header').data('is-scrolled-point') ){

            let is_scrolled_point = $('.site-header').data('is-scrolled-point');

            check_is_scrolled();

            $(window).on( "scroll", function() {
                check_is_scrolled();
            });

            function check_is_scrolled(){
                if( $(window).scrollTop() > is_scrolled_point){
                    $('body').addClass('is-scrolled');
                } else {
                    $('body').removeClass('is-scrolled');
                }
            }

        }    
    }


	/* sliders */

	if( $(".slider-module .owl-carousel").length > 0 ){

        $(".slider-module .owl-carousel").each(function(){

            let module = $(this).closest('.slider-module');
            let margin = parseInt(module.data('margin'));
            let items_desktop = parseInt(module.data('items-desktop'));
            let items_desktop_small = parseInt(module.data('items-desktop-small'));
            let items_tablet = parseInt(module.data('items-tablet'));
            let items_mobile = parseInt(module.data('items-mobile'));
            let autoplay = module.data('autoplay') == 1 ? true : false;
            let nav = module.data('navigation-arrows') == 1 ? true : false; 
            let dots = module.data('pagination') == 1 ? true : false; 
            let autoplayTimeout = parseInt(module.data('timing'));
            let smartSpeed = parseInt(module.data('animation-speed'));

            $(this).owlCarousel({
                nav: 				nav,
                dots: 				dots,
                loop: 				true,
                autoplay: 			autoplay,
                autoplayTimeout: 	autoplayTimeout,
                smartSpeed: 		smartSpeed,
                autoplayHoverPause: true,
                margin:             margin,
                responsive:{
                    0:{
                        items: items_mobile
                    },
                    768:{
                        items: items_tablet
                    },
                    1025:{
                        items: items_desktop_small
                    },
                    1281:{
                        items: items_desktop
                    }                   
                }                
            });

        });

	}


	/* accordians */

	if( $(".accordian-module").length > 0 ){

		let accordian_animation_speed = 500;

		$('.accordian-module button').click(function(){
			
			let accordian = $(this).closest('.accordian-module');
			let view_one = accordian.hasClass('view-one-only');
			let no_scroll = accordian.hasClass('no-scroll');
			let item = $(this).closest('.item');
			let active_item = accordian.find('.item.active');
			let button = $(this);
			let content = item.find('.content');
			let is_active = item.hasClass('active');

			// if view one mode is on, slide up the currently active item, if it hasn't been selected again
			if(view_one && !is_active){
				
				active_item.removeClass('active');
				active_item.find('.content').slideUp(accordian_animation_speed);

			}

			// if view one mode is off and the current item is active, hide it again
			if( !view_one && is_active  ){

				content.slideUp(accordian_animation_speed);
				item.removeClass('active');

			} else {

				content.slideDown(accordian_animation_speed);
				item.addClass('active');

				if(!no_scroll){
					scroll_to_elem(item);
				}

			}

			return false;

		});

		// if accordian item is active on page load then open it
		if( $(".accordian .item.active").length > 0 ){

			let item = $(".accordian .item.active");
			let content = item.find('.content');

			content.slideDown(accordian_animation_speed);

		}

	}


	/* tabs */

	if( $(".tabs-module").length > 0 ){

		$('.tab-button').click(function(){

			let tabs = $(this).closest('.tabs-module');
			let li = $(this).closest('li');
			let index = li.index() + 1;

			tabs.find('.nav li, .tab').removeClass('active');

			tabs.find('.nav ul li:nth-of-type(' + index + '), .content .tab:nth-of-type(' + index + ')').addClass('active');

            return false;

		});

	}


	/* gallery */

	if( $('.gallery-module').length > 0 ){

        let gallery_count = 1;

        $('.gallery-module').each(function(){

            $(this).attr('id', 'gallery-module-' + gallery_count);

            lightGallery(document.getElementById('gallery-module-' + gallery_count), { 
                plugins: [lgZoom, lgThumbnail],
                speed: 500
            });

            gallery_count++;

        });

	}


	/* scroll to links */
	
	// on page load
	if( $('#_' + window.location.hash.replace('#', '')).length > 0 ){

		let elem = $('#_' + window.location.hash.replace('#', ''));
	    if(elem) {

	    	setTimeout(function(){

				scroll_to_elem(elem);

		    }, 500);
	         
	    }

	}

	// buttons
	if( $('.trigger-scroll-to-elem').length > 0 ){

		$('.trigger-scroll-to-elem').click(function(){

			let elem = $( $(this).data('elem') );

			scroll_to_elem(elem);
		
		});

	}


	/* preload images */

	if( $('.preload-item').length > 0 ){
		let preload_image_array = [];
		$('.preload-item').each(function(){
			preload_image_array.push($(this).data("src"));
		});
		preload_images(preload_image_array);
	}

	


	/* - - overlays - - */

	$('.overlay-close-btn').click(function(){
		$(this).closest('.site-overlay').removeClass('active');
	});

	// search overlay
	$('.trigger-site-search').click(function(){
		$('.site-search-overlay').addClass('active');
		return false; 
	});



	/* - - popups - - */

    // immediately show any popup with the class show-on-load
    if( $('.site-popup.show-on-load').length > 0 ){
        let popup = $('.site-popup.show-on-load');
        // if a delay of over 0 is set then show the popup after the time has elapsed
        if( popup.data('popup-delay') ) {
            let delay = parseInt( popup.data('popup-delay') );
            if(delay > 0){
                setTimeout(function(){
                    popup.fadeIn(300);
                },delay);
            } else {
                popup.fadeIn(300);
            }
        } else {
            // else show the popup immediately
            popup.fadeIn(300);
        }
        
    }

    // global class for triggering popups
	$('.trigger-popup').click(function(){

        // if specific popup is targetted, show that popup
        if( $(this).data('popup-id') ) {
            $('#' + $(this).data('popup-id')).fadeIn(300);
        } else {
            // else, just show whichever popup is present
            $('.site-popup').fadeIn(300);
        }		
  
		return false;
  
	});
  
    // close popup by clicking the close button or the popup background
	$('.site-popup .popupCloseButton, .site-popup').click(function(){
	  $('.site-popup').fadeOut(300);
	  return false;	
	});
  
	// prevent popup from closing if popup content is clicked
    $(".site-popup-content-wrapper").on('click', function(event){
	  event.stopPropagation()
	});



	/* - - woocommerce - - */

	// product quantity modifier buttons

	$('.quantity .quantity-modifier').click(function(){

		let qty_input = $(this).closest('.quantity').find('.qty');

		if( $(this).hasClass('minus') ){
			if(qty_input.val() > 1){
				qty_input.val( parseInt(qty_input.val()) - 1 );
				enable_update_cart_btn();
			}
		}

		if( $(this).hasClass('plus') ){
			qty_input.val( parseInt(qty_input.val()) + 1 );
			enable_update_cart_btn();	
		}

		return false;

	});

	function enable_update_cart_btn(){
		$('button[name="update_cart"]').removeAttr("disabled");
	}


    // overwrite woocommerce scroll to notices

    $.scroll_to_notices=function( scrollElement ) {
        var offset = $('.site-header').height();
        if ( scrollElement.length ) {
        $( 'html, body' ).animate( {
            scrollTop: ( scrollElement.offset().top-offset )
            }, 1000 );
        }
    };



    /* - - category search module - - */

    if( $('.cat-search-module').length > 0 ){

        // keyword filtering

        let cats = $('.cat-search-module .cats li');

        $('.cat-search').on('input', function() {
            filter_cats_by_keyword( $(this).val() );
        });

        function filter_cats_by_keyword(keyword){

            let cats_found = false;

            $('.cat-search-term').html(keyword);

            cats.each(function(){

                let cat = $(this);

                let cat_name = cat.html();
                
                cat_name = cat_name.toLowerCase();
                
                keyword = keyword.toLowerCase();

                if (cat_name.indexOf(keyword) >= 0){
                    cat.css('display','block');
                    cats_found = true;
                } else {
                    cat.css('display','none');
                }

            });      
            
            if(cats_found){
                $('.cat-search-empty').css('display','none');
            } else {
                $('.cat-search-empty').css('display','block');
            }

        }
        


        // letter filtering

        let cat_letter_buttons = $('.cat-letters button');

        filter_cats_by_letter('a');

        function filter_cats_by_letter(letter){
            let selected_letter_items = $('.cat-search-module .cats li.cat-letter-' + letter);
            $('.cat-search-module .cats li').css('display','none');
            
            if(selected_letter_items.length > 0){
                $('.cat-search-module .cats li.cat-letter-' + letter).css('display','block');
            } else {
                $('.cat-search-empty').css('display','block');
            }
        }

        cat_letter_buttons.click(function(){
            let button = $(this);
            if(button.hasClass('disabled')){
                return false;
            }
            let letter = button.data('letter');
            cat_letter_buttons.removeClass('active');
            button.addClass('active');
            filter_cats_by_letter( letter );
            $('.cat-search-term').html(letter.toUpperCase());
            $('.cat-search-empty').css('display','none');
        });

    }



	/* - - useful functions - - */

    let css_variables = window.getComputedStyle(document.body);
    let section_spacing_desktop = parseInt(css_variables.getPropertyValue('--section-spacing-desktop'));
    let section_spacing_tablet = parseInt(css_variables.getPropertyValue('--section-spacing-tablet'));
    let section_spacing_mobile = parseInt(css_variables.getPropertyValue('--section-spacing-mobile'));

	function scroll_to_elem(elem){     

        // animate
		$('html, body').animate({
			scrollTop: (elem.offset().top)
		}, 1000, 'swing');

	}

	function fadeOutAndHide(element){
		element.animate({'opacity':0},400,function(){
			element.css('visibility','hidden');
		});
	}

	function fadeInAndShow(element){
		element.css('visibility','visible');
		element.animate({'opacity':1},400);
	}

	function preload_images(preload) {
		var images = [];
		for (i = 0; i < preload.length; i++) {
			images[i] = new Image();
			images[i].src = preload[i];
		}
	}

});